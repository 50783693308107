import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class Product extends React.Component{
    constructor(props){
        super(props)
    }
   
    rawMarkup(){
        var rawMarkup = this.props.product.shortDescription
        return { __html: rawMarkup };
    }

    rawMarkupHtml(){
        var rawMarkup = this.props.product.htmlDescription
        return { __html: rawMarkup };
    }

      render() {
        function refreshPage(){ 
            window.location.reload(); 
        }
        const items = this.props.applications.map((a) =>
            <li key={a.id}>
                <button type="button" onClick={ refreshPage } className="btn btn-light"><Link to={`/Applications/${a.id}`}>{a.name}</Link></button>
            </li>
        );
        
        return(
            <div className="subpage-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ingress" dangerouslySetInnerHTML={this.rawMarkup()} />
                            <div className="html-description" dangerouslySetInnerHTML={this.rawMarkupHtml()} />
                            <ul className="applications-list">
                                {items}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Product;