import React, { Component } from 'react';

class ProductVideos extends React.Component{
    constructor(props){
        super(props)
    }
   
    rawMarkup(){
        var rawMarkup = this.props.videoHtml
        return { __html: rawMarkup };
    }

    render() {

        
        return(
            <div className="product-video-container">
                <div className="subpage-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="video" dangerouslySetInnerHTML={this.rawMarkup()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductVideos;