import React, { Component } from 'react';

class ProductDocuments extends React.Component{
    constructor(props){
        super(props)
    }

    render() {
        const items = this.props.documents.map((a) =>
            <li key={a.id}>
                <a className="btn btn-light" href={a.URL}>{a.name}<i className="fa fa-download"></i></a>
            </li>
        );

        return(
            <div className="product-document-container">
                <div className="subpage-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="document-list">
                                    {items}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductDocuments;