import React, { Component } from 'react';

class ProductImages extends React.Component{
    constructor(props){
        super(props)
    }
   
      render() {
        const items = this.props.images.map((a) =>
            <li key={a.id}>
                <img src={a.URL} />
            </li>
        );
        
        return(
            <div className="product-image-container">
                <div className="subpage-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="image-list">
                                    {items}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductImages;