import React, { Component } from 'react';
import '../../styles/App.css';
import axios from "axios";
import LangContext from '../LangContext';

class Contact extends React.Component{
    static contextType = LangContext;

    state={
        contact: {}
    };

    componentDidUpdate(prevProps, prevState){
        if(prevState.language !== this.context.language){ 
        this.state.language = this.context.language;

            let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetContactInfo";
            let value = this.context;

            let axiosConfig = {
                headers: {
                    'Content-Language': value.language
                }
            };

            axios
            .get(pr, axiosConfig)
            .then(response => {
                const newApplication = response.data;

                const newState = Object.assign({}, this.state, {
                    contact: newApplication
                });

                this.setState(newState);
            })

            .catch(error => console.log(error));

        }
    } 

    componentDidMount(){
        let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetContactInfo";

        axios
        .get(pr)
        .then(response => {
            const newApplication = response.data;

            const newState = Object.assign({}, this.state, {
                contact: newApplication
            });

            this.setState(newState);
        })

        .catch(error => console.log(error));
    }

    rawMarkup(){
        var rawMarkup = this.state.contact.shortDescription
        return { __html: rawMarkup };
    }
    rawMarkupHtml(){
        var rawMarkup = this.state.contact.htmlDescription
        return { __html: rawMarkup };
    }
    render(){
        return(
            <div className="Contact">
            <div className="subpage-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{this.state.contact.name}</h1>
                            <div className="ingress" dangerouslySetInnerHTML={this.rawMarkup()} />
                            <div className="html-description" dangerouslySetInnerHTML={this.rawMarkupHtml()} />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default Contact;