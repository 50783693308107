import React, { Component } from 'react';
import '../../styles/App.css';
import '../../styles/Header.css';
import logo from './logo.svg';
import LangContext from "../LangContext";
import {
   
    NavLink 
  } from 'react-router-dom';

class Header extends React.Component{

    render() {
        const Button = ({ value, text, onClick }) => (
            <button
              value={value}
              onClick={event => onClick({ value: event.target.value })}
            >{text}</button>
          );

      return (
          <div className="Header">
              <div className="logo-banner">
              <NavLink className="Aprobo-logo-container" to={`/`}>
                        <img src={logo} className="Aprobo-logo" alt="logo" />
               </NavLink>

                
                  <LangContext.Consumer>
                        {({ setLanguage }) => (
                            <div className="language">
                                <Button text='sv' value='sv-se' onClick={setLanguage} />
                                <Button text='eng' value='en-us' onClick={setLanguage} />
                            </div>
                        )}
                  </LangContext.Consumer>
               </div>
          </div>
      );
  }
}


export default Header;




