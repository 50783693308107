import React from 'react';
import Header from '../components/Header/Header';
import Goback from '../components/Header/Goback';
import axios from "axios";
import LangContext from './LangContext';

class Notfound extends React.Component{
    static contextType = LangContext;

    state={
        notFound: {}
    };

    componentDidUpdate(prevProps, prevState){
            if(prevState.language !== this.context.language){ 
            this.state.language = this.context.language;

                let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetPageNotFoundInfo";
                let value = this.context;

                let axiosConfig = {
                    headers: {
                        'Content-Language': value.language
                    }
                };

                axios
                .get(pr, axiosConfig)
                .then(response => {
                    const newnotFound = response.data;

                    const newState = Object.assign({}, this.state, {
                        notFound: newnotFound
                    });

                    this.setState(newState);
                })

                .catch(error => console.log(error));
        } 
    }

    componentDidMount(){
        let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetPageNotFoundInfo";

                axios
                .get(pr)
                .then(response => {
                    const newnotFound = response.data;

                    const newState = Object.assign({}, this.state, {
                        notFound: newnotFound
                    });

                    this.setState(newState);
                })

                .catch(error => console.log(error));
    }

    rawMarkup(){
        var rawMarkup = this.state.notFound.shortDescription
        return { __html: rawMarkup };
    }
    rawMarkupHtml(){
        var rawMarkup = this.state.notFound.htmlDescription
        return { __html: rawMarkup };
    }
    render(){
        return(
            <div className="page-not-found">
                <Header />
                <div className="header-banner">
                  <Goback />
                  <h2 className="header-title">{this.state.notFound.name}</h2>
               </div>
                <div className="subpage-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>{this.state.notFound.name}</h1>
                                <div className="ingress" dangerouslySetInnerHTML={this.rawMarkup()} />
                                <div className="html-description" dangerouslySetInnerHTML={this.rawMarkupHtml()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notfound;
