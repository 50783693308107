import React from 'react';
import '../../styles/App.css';
import axios from "axios";
import Header from '../Header/Header';
import LangContext from '../LangContext';

class Products extends React.Component{
    static contextType = LangContext;

    constructor(){
        super();
            this.state={
                products: [],
                productImage: {},
                language: "",
                setLang: ({ value }) => this.setState({ language: value })
            };
      }

     componentDidUpdate(prevProps, prevState){
        if(prevState.language !== this.context.language){ 
        this.state.language = this.context.language;

        let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetAllProducts";

        let value = this.context;

        let axiosConfig = {
            headers: {
                'Content-Language': value.language
            }
        };
        
        axios
        .get(pr, axiosConfig)
        .then(response => {
            const newProducts = response.data;

            const newState = Object.assign({}, this.state, {
                products: newProducts,
                productImage: newProducts.productImage

            });

            this.setState(newState);
        })

        .catch(error => console.log(error));
        }
    }

    componentDidMount(){
        let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetAllProducts";
 
        axios
        .get(pr)
        .then(response => {
            const newProducts = response.data;

            const newState = Object.assign({}, this.state, {
                products: newProducts,
                productImage: newProducts.productImage

            });

            this.setState(newState);
        })

        .catch(error => console.log(error));
    }

    render(){
        const items = this.state.products.map((p) =>
            <li key={p.id}>
                <a href={`/products/${p.id}`}>
                    <img src={p.productImageUrl}/>
                </a>
            </li>
        );
        console.log(this.context);

        
        return(
            <div className="product-container">
                <Header />
                <div className="header-banner">
                    <LangContext.Consumer>
                        {({ translations }) => (
                        <h2 className="header-title">{translations["products_title"]}</h2>
                        )}
                    </LangContext.Consumer>
               </div>
                <div className="subpage-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="products">
                                    {items}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Products;
