import React, { Component } from 'react';
import '../../styles/App.css';
import Header from "../Header/Header"
import axios from "axios";
import Goback from '../Header/Goback';
import LangContext from '../LangContext';
import ProductDesscription from '../Product/ProductDescription';
import ProductDocuments from '../Product/ProductDocuments';
import ProductImages from '../Product/ProductImages';
import Product from '../Product/Product';
import Contact from '../Conatct/Contact';
import {
    Route,
    NavLink,
    BrowserRouter as Router,
    Switch,
    Redirect
  } from 'react-router-dom';

class Application extends React.Component{
    static contextType = LangContext;

    state={
        application: {}
    };


      componentDidUpdate(prevProps, prevState){
        if(prevState.language !== this.context.language){ 
        this.state.language = this.context.language;

            let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetproductApplication/" + this.props.match.params.id;
            let value = this.context;

            let axiosConfig = {
                headers: {
                    'Content-Language': value.language
                }
            };

            axios
            .get(pr, axiosConfig)
            .then(response => {
                const newApplication = response.data;

                const newState = Object.assign({}, this.state, {
                    application: newApplication,
                });

                this.setState(newState);
            })

            .catch(error => console.log(error));
        }
    }

    componentDidMount(){
        let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetproductApplication/" + this.props.match.params.id;
    
        axios
        .get(pr)
        .then(response => {
            const newApplication = response.data;

            const newState = Object.assign({}, this.state, {
                application: newApplication
            });

            this.setState(newState);
        })

        .catch(error => console.log(error));
    }
    rawMarkup(){
        var rawMarkup = this.state.application.shortDescription
        return { __html: rawMarkup };
    }

    rawMarkupHtml(){
        var rawMarkup = this.state.application.htmlDescription
        return { __html: rawMarkup };
    }

    render() {
        return(
            <div className="applications-page">
                <Header />
                <div className="header-banner">
                    <Goback />
                    <h2 className="header-title"><a href="/products">{this.state.application.name}</a></h2>
                </div>
                <div className="subpage-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ingress" dangerouslySetInnerHTML={this.rawMarkup()} />
                                <div className="html-description" dangerouslySetInnerHTML={this.rawMarkupHtml()} />
                            </div>
                        </div>
                    </div>
                </div>
                    <LangContext.Consumer>
                        {({ translations }) => (
                            <div className="product-menu-container">
                                <ul className="product-menu">
                                    <li>
                                        <NavLink activeClassName="active" to={`/product/Videos/${this.state.application.productid}`}>
                                            <i className="fa fa-film"></i>
                                            {translations["product_footermenu_videolink"]}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active"  to={`/product/Documents/${this.state.application.productid}`}>
                                            <i className="fa fa-file"></i>
                                            {translations["product_footermenu_documentlink"]}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to={`/product/Images/${this.state.application.productid}`}>
                                            <i className="fa fa-image"></i>
                                            {translations["product_footermenu_imagelink"]}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/Contact">
                                            <i className="fa fa-info-circle"></i>
                                            {translations["product_footermenu_contactlink"]}
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>   
                        )}
                    </LangContext.Consumer>
            </div>
        )
    }
}

export default Application;