import React from 'react';
import { withRouter } from 'react-router-dom';
// Icon page https://react-icons-kit.now.sh/guide 
import { Icon } from 'react-icons-kit';
import {arrow_left} from 'react-icons-kit/ikons/arrow_left';


const GoBack = ({ history }) => (
    <Icon className="back-icon" size={32} icon={arrow_left} onClick={() => history.goBack()} />
);

export default withRouter(GoBack);
