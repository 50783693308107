import React, { Component } from 'react';
import '../styles/App.css';
import {Redirect} from 'react-router-dom';
import logo from '../img/db-Consept.png';

class Main extends React.Component{
    state = {
        redirect: false
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 3000)
    }

      render() {
        if (this.state.redirect) {
            return (
                <Redirect to={'/products'} />
            )
        }

        return (
            <div className="main-section">
                <div className="main">
                    <img src={logo} className="Aprobo-logo-spin" alt="logo" />
                    <h1>Concept</h1>
                </div>
            </div>
        );
    }
}

export default Main;