import React, { Component, useContext } from 'react';
import '../../styles/App.css';
import Header from '../Header/Header';
import axios from "axios";
import ProductDesscription from './ProductDescription';
import ProductDocuments from './ProductDocuments';
import ProductImages from './ProductImages';
import ProductVideos from './ProductVideos';
import Contact from '../Conatct/Contact';
import Goback from '../Header/Goback';
import LangContext from '../LangContext';
import {
    Route,
    NavLink,
    BrowserRouter as Router,
    Switch,
    Redirect
  } from 'react-router-dom';

class Product extends React.Component{
    static contextType = LangContext;

    constructor(){
        super();
            this.state={
                product: {},
                applications: [],
                documents: [],
                images: [],
                videoHtml: [],
                language: {}
            };
      }

        componentDidUpdate(prevProps, prevState){
            if(prevState.language !== this.context.language){ 
                this.state.language = this.context.language;

                let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetProduct/" + this.props.match.params.id;
                let value = this.context;

                let axiosConfig = {
                    headers: {
                        'Content-Language': value.language
                    }
                };

                axios
                .get(pr, axiosConfig)
                .then(response => {
                    const newProduct = response.data;

                    const newState = Object.assign({}, this.state, {
                        product: newProduct,
                        applications: newProduct.applications,
                        documents: newProduct.documents,
                        images: newProduct.images,
                        videoHtml: newProduct.videoHtml
                    });
                    this.setState(newState);
                })

                .catch(error => console.log(error));
            }
        }

        componentDidMount(){
            let pr = "https://aprobo.inkadev.nu/umbraco/api/Products/GetProduct/" + this.props.match.params.id;
 
            axios
            .get(pr)
            .then(response => {
                const newProduct = response.data;

                const newState = Object.assign({}, this.state, {
                    product: newProduct,
                    applications: newProduct.applications,
                    documents: newProduct.documents,
                    images: newProduct.images,
                    videoHtml: newProduct.videoHtml
                });
                this.setState(newState);
            })

            .catch(error => console.log(error));
        }


      render() {
        return(
            <div className="product-container">
                    <Router>
                         <Header />
                            <div className="header-banner">
                                <Goback />
                                <h2 className="header-title">
                                <NavLink activeClassName="" to={`/products/${this.props.match.params.id}`}>
                                {this.state.product.name}
                                    
                                    </NavLink>{/*<a href={`/products/${this.props.match.params.id}`}>{this.state.product.name}</a>*/} 
                                </h2>
                            </div>
            
                        <Switch>
                            <Redirect from='/Products/:id' to='/Product/Description/:id'/>
                            <Route path="/Product/Description/:id" render={(props) => <ProductDesscription {...props} product={this.state.product} applications={this.state.applications}/>} />
                            <Route path="/Product/Videos/:id" render={(props) => <ProductVideos {...props} videoHtml={this.state.videoHtml}/>} />
                            <Route path="/Product/Documents/:id" render={(props) => <ProductDocuments {...props} documents={this.state.documents}/>} />
                            <Route path="/Product/Images/:id" render={(props) => <ProductImages {...props} images={this.state.images}/>} />
                            <Route path="/Contact" component={Contact} />
                        </Switch>
                        <LangContext.Consumer>
                            {({ translations }) => (
                                <div className="product-menu-container">
                                    <ul className="product-menu">
                                        <li>
                                            <NavLink activeClassName="active" to={`/product/Videos/${this.props.match.params.id}`}>
                                                <i className="fa fa-film"></i>
                                                {translations["product_footermenu_videolink"]}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active"  to={`/product/Documents/${this.props.match.params.id}`}>
                                                <i className="fa fa-file"></i>
                                                {translations["product_footermenu_documentlink"]}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to={`/product/Images/${this.props.match.params.id}`}>
                                                <i className="fa fa-image"></i>
                                                {translations["product_footermenu_imagelink"]}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/Contact">
                                                <i className="fa fa-info-circle"></i>
                                                {translations["product_footermenu_contactlink"]}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>   
                            )}
                        </LangContext.Consumer>
                </Router>
            </div>
        )
    }
}

export default Product;