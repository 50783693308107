import React, { Component } from 'react';
import '../styles/App.css';

import Main from './Main';
import {Route, NavLink, BrowserRouter as Router, Switch} from 'react-router-dom'
import Products from './Product/Products';
import Product from './Product/Product';
import Applications from './Applications/Applications';
import Notfound from './Notfound';
import LangContext from './LangContext';
import axios from "axios";
import ReactGA from "react-ga4";



class App extends Component {
    constructor(){
        super();
          this.state={
                results: {},
                translations: [],
                language: "sv-se",
                setLanguage: ({ value }) => {
                    this.setState({ language: value });

                    let translations ="https://aprobo.inkadev.nu/umbraco/api/Products/GetDictionaryItems";

                    let axiosConfig = {
                        headers: {
                            'Content-Language': value
                        }
                    };
                    
                    axios
                    .get(translations, axiosConfig)
                    .then(response => {
                        const newTranslations = response.data;
                        this.setState({ translations: newTranslations[0] });
                    })

                    .catch(error => console.log(error));
                }

          }
      }

        componentDidMount(){
            let translations ="https://aprobo.inkadev.nu/umbraco/api/Products/GetDictionaryItems";
            ReactGA.initialize("G-7Z58GVV2EH");
            let axiosConfig = {
                headers: {
                    'Content-Language': this.state.language
                }
            };

            axios
            .get(translations, axiosConfig)
            .then(response => {
                const newTranslations = response.data;
                this.setState({ translations: newTranslations[0] });
            })
            .catch(error => console.log(error));
        }

      render() {
        return (
            <div className="App">
                <div className="main-container">
                    <LangContext.Provider value={this.state}>
                        <Router>
                            <div className="main-router">
                                <Switch>
                                    <Route exact path="/" component={Main} />
                                    <Route exact path="/Products" component={Products} />
                                    <Route path="/Products/:id" component={Product} />
                                    <Route path="/Product/Description/:id" component={Product} />
                                    <Route path="/Product/Documents/:id" component={Product} />
                                    <Route path="/Product/Images/:id" component={Product} />
                                    <Route path="/Product/Videos/:id" component={Product} />
                                    <Route path="/Contact" component={Product} />
                                    <Route path="/Applications/:id" component={Applications} /> 
                                    <Route component={Notfound} />
                                </Switch>
                            </div>
                        </Router>
                    </LangContext.Provider>
                </div>
            </div>
        );
    }
}

export default App;

